<template>
  <div id="notifications">
    <b-row>
      <b-col cols="6" class="mx-auto">
        <b-card :title="$t('notifications.Notifications')">
          <div class="alert alert-danger p-1" v-if="error">{{ error }}</div>

          <b-link
            :href="getNofifictionURI(notification)"
            v-for="(notification, index) in notifications"
            :key="`noti_${index}`"
          >
            <b-media
              :class="{
                'border-bottom': true,
                'py-1': true,
                'px-1': true,
                'bg-light-info': notification.read == false,
              }"
            >
              <template #aside>
                <b-avatar size="32" icon="" />
              </template>

              <div class="notification-text text-dark">
                {{
                  $i18n.locale == "en"
                    ? notification.data.messageEnglish
                      ? notification.data.messageEnglish.title
                      : ""
                    : notification.data.messageArabic
                    ? notification.data.messageArabic.title
                    : ""
                }}
              </div>
              <div class="notification-text text-muted">
                {{ moment(notification.createdAt).locale($i18n.locale).fromNow(false) }}
              </div>
            </b-media>
          </b-link>

          <div class="text-muted text-center" v-if="loading">
            <strong>{{ $t("notifications.Loading") }}</strong>
          </div>
          <div
            class="text-center"
            v-if="!loading && !error && loadedNotifications < totalNotifications"
          >
            <b-button variant="link" @click="loadNotifications()">{{
              $t("Timeline.Load more")
            }}</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BCardBody, BRow, BCol, BLink, BButton, BMedia, BAvatar } from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
export default {
  name: "Notifications",
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BButton,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      notifications: [],
      totalNotifications: 0,
      loadedNotifications: 0,
      currentPage: 0,
      perPage: 10,
      loading: true,
      error: "",
    };
  },
  methods: {
    moment: function (input) {
      return moment(input);
    },
    loadNotifications: async function () {
      try {
        const formData = new FormData();
        this.currentPage += 1;
        formData.append("page", this.currentPage);
        formData.append("perPage", this.perPage);
        this.loading = true;
        const { data } = await axios.post("/users/notifications/list/", formData);
        this.loading = false;
        this.totalNotifications = data.total;
        this.loadedNotifications += data.data.length;
        this.notifications = [...this.notifications, ...data.data];
      } catch (e) {
        this.error = e.message;
      }
    },
    getNofifictionURI(n) {
      if (n.type == "followedUser" && n.userId?.username) {
        return `/profile/${n.userId.username}`;
      }
    },
  },
  mounted() {
    this.loadNotifications();
  },
};
</script>
<style>
.bg-gray {
  background-color: #f1f1f1;
}
</style>
